<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}字典</div>

        <div class="content" v-loading="loading">
            <el-form label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="字典名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入字典名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="字典代码" prop="code">
                    <el-input v-model="form.code" placeholder="请输入字典代码" @keyup.enter.native="submit"></el-input>
                </el-form-item>


            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import mixinsForm from '@/mixins/form'
export default {
    mixins: [mixinsForm],
    data() {
        return {
            api:{
                save:this.$api['admin/dictClassify'].save,
            },
            rules: {
                name: [{ required: true, message: '请输入字典名称', trigger:['blur','change'] }],
                code: [{ required: true, message: '请输入字典代码', trigger:['blur','change'] }],
            },
        };
    },
    methods: {

    },
};
</script>
